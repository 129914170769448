/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

  function burger() {
    $(".nav-icon").click(function () {
      $(this).toggleClass("open");
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
  
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        burger();
        $(document).ready(function() {
          $(".dropdown-toggle").dropdown();
        });

        var status = getUrlParameter('cm_status');
        var type = getUrlParameter('cm_archive');
        if ((status === 'auktion_archive') || (status === 'vergangene')) {
          status = 'Vergangene Auktionen';
          $('li a[title="'+status+'"]').addClass('current-menu-item');
        } else {
         $('li a[title="'+status+'"]').addClass('current-menu-item');
        }
        
        $('.schatzungen .subnav ul.current-menu-item').addClass('current-menu-parent');
   
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        $('.slick').slick({
          autoplay : true,
          arrows : false,
          speed: 2500,
          autoplaySpeed: 5000,
          fade : true
        });
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'uber_uns': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
     
        $(".lightbox a").simpleLightbox({
          overlay : true, 
          nav : false,
          doubleTapZoom : 1,
          scrollZoom : false
      });
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {


        $(".lightbox a").simpleLightbox({
          overlay : true, 
          nav : false,
          doubleTapZoom : 1,
          scrollZoom : false
      });


      $(".onlineauktion a").simpleLightbox({
        overlay : true, 
        nav : false
    });

      /*
      let gallery = $('.lightbox a').simpleLightbox();
      gallery.on('shown.simplelightbox', function (evt) {

        if ($('.has-lightbox').length) {
          console.log('yes');
          $('.has-lightbox').click(function(evt){

            gallery.close();
            $('.sl-wrapper').remove();
            $('.sl-overlay').remove();
          });
        }
        });
        */
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
